export default {
  getPathData(path, styleDict, defaultStyle) {
    const color = styleDict[path.new_style_key] || defaultStyle.color;
    const coords = path.points.map(p => [p[1], p[2]]);
    const time = path.points.map(p => p[0]);
    const unit_id = path.unit_id;

    return { color, coords, time, unit_id };
  },

  getShadowLayer(currentWeight) {
    return {
      color: 'gray',
      weight: currentWeight + 3,
      opacity: 0.4,
      filter: 'drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.16))',
    };
  },

  getBorderLayer(currentWeight) {
    return {
      color: '#fff',
      weight: currentWeight + 2,
    };
  },
};
