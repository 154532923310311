import Vue from 'vue'
import trackService from './services/trackService';

/**
 * ps: работу с картой в main.vue необходимо производить через статические методы работы с картой,
 * т.к. для main на момент рендеринга карты не существует..
 * */
export class MapInstance {

  static map = Vue.observable({
    map: null,
  });

  static tracksOnMap = Vue.observable({});

  static init(map) {
    this.map = map
    this._addListeners()
  }

  static getMap() {
    return this.map;
  }

  static getZoom() {
    return this.map.getZoom();
  }

  static hasLayer(layer) {
    return this.map.hasLayer(layer)
  }

  static removeLayer(layer) {
    return this.map.removeLayer(layer)
  }

  static addLayer(layer) {
    return this.map.addLayer(layer)
  }

  static fitBounds(bounds, options = {}) {
    this.map.fitBounds(bounds, options)
  }

  static setView(center, zoom, options) {
    this.map.setView(center, zoom, options)
  }

  static getCenter() {
    return this.map.getCenter();
  }

  static getBounds() {
    return this.map.getBounds();
  }

  /**
   * 
   * @param {Object} trackData - Обьект
   * @param {Array} - trackData.columns
   * @param {Object} - trackData.legenda
   * @param {String} - trackData.process_id
   * @param {Number} - trackData.sum_distance
   * @param {Array} - trackData.values
   * @returns {Array}
   */
  static functionDrawTrackOnMap(trackData, currentWeight) {
    const tracks = {};
    const { process_id, values, report_id } = trackData;

    const styleDict = {};
    trackData?.legenda?.newStyles.forEach(style => {
      styleDict[style.key] = style.hex;
    });

    tracks[process_id] = values.map(path => {
      const { color, coords, time, unit_id } = trackService.getPathData(path, styleDict);
      const shadowLayer = trackService.getShadowLayer(currentWeight);
      const borderLayer = trackService.getBorderLayer(currentWeight);
      return L.polyline(coords, {
        color,
        time,
        unit_id,
        weight: currentWeight,
        shadowLayer,
        borderLayer,
        originalStyle: color,
        report_id,
      });
    });

    this.tracksOnMap[process_id] = L.featureGroup(tracks[process_id]);
    this.tracksOnMap[process_id].addTo(this.map);
  }

  static functionDeleteTrackFromMap(report) {
    console.log(report, this.tracksOnMap, 'hello2');
    if (this.tracksOnMap[report.process_id]) {
      this.map.removeLayer(this.tracksOnMap[report.process_id]);
    }
  }

  /**
   * Условно закрытые методы
   * */
  static _addListeners() {
    this.map.on('moveend', (e) => {
      // console.log('MAP Move end', e)
    })

    this.map.on('baselayerchange', (e) => {
      // console.log('MAP Baselayer change', e)
    })

    this.map.on('mouseup', (e) => {
      // console.log('MAP mouseup', e)
    })

    this.map.on('mouseout', (e) => {
      // console.log('MAP mouseout', e)
    })

    this.map.on('click', (e) => {
      // console.log('MAP click', e)
    })

    this.map.on('zoomend', (e) => {
      // console.log('MAP zoomend', e)
    })
  }

}
