<template>
  <div class="absolute inset-0 overflow-hidden">
    <navigation v-if="isNavigation" :spinner-loading="spinnerLoading" @users="showUser" @geozones="showGeozone" @notifications="showNotification" @reports="showReports" @units="showObjects" @monitoring="showMonitoring" @mailing="showMailing" @race="showRace" @adminpanel="isAdminPanel = true" />

    <leaflet-map ref="leaflet-map" :is-active-monitoring-button="isActiveMonitoringButton" :is-monitoring-manipulation="isMonitoringManipulation" :is-monitoring-button="isMonitoringButton" :is-monitoring="isMonitoring" :marker-cluster="markerCluster" :spinner-loading="spinnerLoading" @new-notification="newNotification" @new-lastpoint="newLastpoint" @new-lastpoint-array="newLastpointArray" @set-websocket="setWebsocket" @showLegend="handleShowLegend" @setMonitoringButton="setActivateMonitoringButton" @reset-notification="gottenNotifications = []" @closeMeasure="isMeasure = false" @delete-marker="deleteActive" @is-active-monitoring-button="isActiveMonitoringButton = false" @marker-cluster="setMarkerCluster" @marker-group="setMarkerGroup" @marker-group-units="setMarkerGroupUnits" @closeReportTable="isTrackLegend = false" />

    <tools :is-monitoring-button="isMonitoringButton" @selectLayer="handlLayer" @addOverlay="overlay => $refs['leaflet-map'].addOverlay(overlay)" @removeOverlay="overlay => $refs['leaflet-map'].removeOverlay(overlay)" @activateMonitoring="$refs['leaflet-map'].activateMonitoring()" @center-map="latlng => $refs['leaflet-map'].setView(latlng)" @toggleNotification="isNotificationPopupTool = !isNotificationPopupTool" @measure-toogle="measureToggle" @update-myself="updateUser" @show-sessions="isSessions = !isSessions" @change-company="closeNotification" @issettings="isUnits = false" @isLogout="handlLogout" @feedback="handleFeedback" @show-history="showHistory" :gotten-notifications="gottenNotifications" />

    <transition name="dialog-fade">
      <race v-if="isRace" @close="hideRace" @show-history="showHistory" />
    </transition>

    <transition :name="isInModule ? '' : 'dialog-fade'">
      <users
        v-if="isUser && activeWindow"
        @close="hideUser"
        @update-user="updateUser"
        @create-user="
          isCreatingUser = true
          isUser = false
        "
        @show-history="showHistory"
      />
    </transition>

    <transition :name="'dialog-fade'">
      <geozones
        v-if="isGeo"
        :is-geo="isGeo"
        @close="hideGeozone"
        @createGeozone="
          isCreatingGeo = true
          isGeo = false
          $store.commit('geozones/START_DRAWING')
        "
        @updateGeozone="updateGeozone"
        @show-history="showHistory"
      />
    </transition>

    <transition :name="isInModule ? '' : 'dialog-fade'">
      <monitoring v-if="isMonitoring" ref="monitoring" :report-tables="reportTables" :is-active-monitoring-button="isActiveMonitoringButton" :from-monitoring="fromMonitoring" :marker-cluster="markerCluster" :marker-group="markerGroup" :marker-group-units="markerGroupUnits" :is-annotation-camera="isAnnotationCamera" :is-opened-annotation="isOpenedAnnotation" @last-coords="setLastCoords" @showLegend="handleShowLegend" @close="hideMonitoring" @show-report="showReport" @update-unit="updateUnit" @update-unit-events="updateUnitEvents" @set-active-ann="setActiveAnnotation" @add-active="addActive" @remove-all="removeAll" @remove-all-arrows="removeAllArrows" @delete-active="deleteActive" @to-reports="toReport" @open-report="openReport" @open-commands="openCommands" @is-active-arrow="isActiveArrow" @monitoring-manipulation="setMonitoringManipulation" @setMonitoringButton="setMonitoringButton" @edit-group="editGroupFromMonitoring" @from-monitoring="setFromMonitoring" @helper-tracking-markers="helperTrackingMarkers" @open-annotation-camera="openAnnotationCamera" @close-annotation-camera="closeAnnotationCameraFromMonitoring" @open-annotaton="isOpenedAnnotation = $event" />
    </transition>

    <transition :name="'dialog-fade'">
      <notifications
        v-if="isNotify"
        @close="hideNotification"
        @create-notify="
          isCreatingNotify = true
          isNotify = false
        "
        @update-notify="updateNotification"
        @show-history="showHistory"
      />
    </transition>

    <add-notify
      v-if="isCreatingNotify"
      :title="1"
      @close="
        isCreatingNotify = false
        isNotify = true
      "
    />

    <add-notify
      v-if="isUpdatingNotify"
      :title="2"
      :notification-to-update="notificationSelected"
      @close="
        isUpdatingNotify = false
        isNotify = true
      "
      @show-history="showHistory"
    />

    <transition name="dialog-fade">
      <reports
        v-if="isReport"
        ref="report"
        :is-demo="isDemoAccount"
        :monitoringunit="unitFromMonitoring"
        :fromMonitoring="isFromMonitoring"
        :isComposeReport="isComposeReport"
        :isReport="isReport"
        :is-refresh-report="isRefreshReport"
        @update-unit="updateUnit"
        @close="hideReports"
        @show-report="showReport"
        @update-unitsgroup="updateUnitsgroup"
        @create-reporttemplate="createReporttemplate"
        @update-reporttemplate="updateReporttemplate"
        @to-monitoring="toMonitoring"
        @show-report-table="showReportTable"
        @group-track="showGroupTrack"
        @group-circle="propsCircleGroupTrack"
        @clear-monitoringunit="unitFromMonitoring = null"
        @showLegend="handleShowLegend"
        @close-report-main="handleCloseReportMain"
      />
    </transition>

    <transition-group name="el-zoom-in-top">
      <template>
        <component v-for="(tab, index) in reportPhotos" :is="photoShow" ref="rowPhoto" :key="index + tab.datepoint" :row="tab" :index="index" @close="handleClosePhoto" />
      </template>
    </transition-group>

    <template>
      <component
        v-for="table in reportTables"
        ref="tablereport"
        :is="table.tableType"
        :key="table.reportId"
        :table-type="table.key"
        :fullTable="table"
        :uniq-report-id="table.reportId"
        :paginated-report.sync="reportsForms[table.reportId]"
        :style="reportStyles[table.reportId]"
        :tracks-done="tracksDone"
        :circle-tracks-group="circleGroupTracks"
        :is-loading-report="isLoadingReport"
        @reload-report="reloadReport"
        @close="closeTab(table)"
        @setSize="setHeight"
        @change-position="changeTablePosition"
        @show-report="showReport"
        @show-report-table="isShowReportTable = false"
        @handle-foto-row="handlFotoRow"
        @removefotoelements="reportPhotos.length = 0"
        @show-delete-message-modal="showDeleteMessageModal"
        @show-compose-report="showComposeReport"
        @show-add-parametrs="showAddParametrs"
        @refresh-report="refreshReport"
        @switchObject="switchObject(table)"
      />
    </template>

    <add-reporttemplate v-if="isReporttemplate" :title="reportTitle" :reporttemplate.sync="reporttemplateSelected" @update-table="updateTable" @add-table="showAddTables" @close="closeReportTemplate" @updateReportTemplate="updateReportTemplates" />

    <add-tables v-if="isAddingTables" :template-id="reporttemplateSelected.id || ''" @added-tables="addedTables" @close="closeAddTables" :current-template="reporttemplateSelected" />

    <edit-tables
      v-if="isEditingTables"
      :table-from-parent="selectedTable"
      @close="
        isEditingTables = false
        isReporttemplate = true
      "
      @update-table="editTable"
      @show-list-key="showListKey"
    />

    <transition name="dialog-fade">
      <!-- NEW OBJECTS -->
      <UnitsList v-if="isUnits" :object-id="editedObjectId" :isFromMonitoring="editFromMonitoring" @close="hideObjects" @show-history="showHistory" @onClearEditedObjectId="editedObjectId = ''" @onBackToMonitoring="backToMonitoring" />
    </transition>

    <add-locator
      v-if="isCreatingLocator"
      :title="1"
      @close="
        isCreatingLocator = false
        isUnits = true
        activeTabUnits = 'locators'
      "
    />

    <add-locator
      v-if="isUpdatingLocator"
      :title="2"
      :locator-to-update="locatorSelected"
      @close="
        isUpdatingLocator = false
        isUnits = true
        activeTabUnits = 'locators'
      "
      @show-history="showHistory"
    />

    <add-trailer
      v-if="isCreatingTrailer"
      :title="1"
      @close="
        isCreatingTrailer = false
        isUnits = true
        activeTabUnits = 'trailers'
      "
    />

    <add-trailer
      v-if="isUpdatingTrailer"
      :title="2"
      :trailer-to-update="trailerSelected"
      @close="
        isUpdatingTrailer = false
        isUnits = true
        activeTabUnits = 'trailers'
      "
      @show-history="showHistory"
    />

    <new-unit-group
      v-if="isCreatingGroup"
      :title="1"
      @close="
        isCreatingGroup = false
        isUnits = true
        activeTabUnits = 'unitsgroups'
      "
    />

    <new-unit-group v-if="isUpdatingGroup" :group="unitGroupSelected" :title="2" @close="closeUpdateUnitsgroup" @show-history="showHistory" />

    <edit-terminal v-if="isUpdatingTerminals" class="top-5 left-5" :item="terminalsData" :title="1" @close="closeDialogTerminal($event)" />

    <transition name="dialog-fade">
      <create-unit
        v-if="isCreatingUnit"
        ref="create-unit"
        :unit="unitSelected"
        :title="1"
        @param1="showParam1"
        @param2="showParam2"
        @close="
          isCreatingUnit = false
          isUnits = true
        "
      />
    </transition>

    <transition name="dialog-fade">
      <create-unit v-if="isUpdatingUnit" ref="update-unit" :title="2" :unit="unitSelected" :admin="isAdminUpdateUnits" @update-unit-events="updateUnitEvents" @param1="showParam1" @param2="showParam2" @close="closeUpdateUnit" @show-history="showHistory" />
    </transition>

    <create-unit-events v-if="isCreatingUnitEvents" ref="update-unit-events" :title="1" :unit="unitSelected" @close="closeUpdateUnit" @show-history="showHistory" :event-to-update="null" />

    <create-unit-events v-if="isUpdatingUnitEvents" ref="update-unit-events" :title="2" :unit="unitSelected" :event-to-update="eventToUpdate" @close="closeUpdateUnit('updated')" @show-history="showHistory" />

    <param12 v-if="isParam12" :title="paramTitle" :param="paramSelected" @save="saveParam" @close="isParam12 = false" />

    <transition :name="isInModule ? '' : 'dialog-fade'">
      <mailing
        v-show="isMailing"
        @close="hideMailing"
        @create-mailing="
          isCreatingMailing = true
          isMailing = false
        "
        @update-mailing="updateMailing"
        @show-history="showHistory"
      />
    </transition>

    <add-mailing
      v-if="isCreatingMailing"
      :title="1"
      @close="
        isCreatingMailing = false
        isMailing = true
      "
    />

    <add-mailing
      v-if="isUpdatingMailing"
      :title="2"
      :mailing="mailingSelected"
      @close="
        isUpdatingMailing = false
        isMailing = true
      "
      @show-history="showHistory"
    />

    <admin-panel v-if="isAdminPanel" :user-updated="userUpdated" :update-item="updateItem" :geoclose="!isAdminPanelGeoCLosed" :update-company="updateCompanySettings" @show-report="showReport" @close="isAdminPanel = false" @admin-edit-route="showRouteAdmin" @admin-edit-trip="showTripAdmin" @admin-edit-groups="showUnitGroupAdmin" @admin-edit-terminals="showTerminalsAdmin" @update-unit="updateUnit" @admin-companies="showCompanyAdmin" @admin-users="showUsersAdmin" @admin-trailer="showTrailerAdmin" @admin-mailings="showMailingsAdmin" @admin-reports-template="showReportsTemplateAdmin" @admin-notifications="showNotificationAdmin" @admin-edit-geozone="showGeozoneAdmin" @show-history="showHistory" @open-wialon="openWialon" @open-kml="openKml" />

    <company-settings
      v-if="isAdminCompanySettings"
      :admin="true"
      :companysettings="companysettings"
      @close="
        isAdminCompanySettings = false
        isAdminPanel = true
      "
      @change-company="saveSettingsChangeCompany"
    />

    <edit-geozones :spinnerLoading="spinnerLoading" v-if="isAdminGeozone" :geozone="selectedGeozoneAdmin" :admin="true" @close="closeAdminGeozone" />

    <add-notify v-if="isAdminNotification" :title="2" :notification-to-update="notificationSelected" :admin="true" @close="isAdminNotification = false">
      <add-reporttemplate v-if="isAdminReportTemplate" :reporttemplate.sync="reporttemplateSelected" :title="2" :admin="true" @close=";(isAdminReportTemplate = false), (isAdminPanel = true)" />
    </add-notify>

    <add-mailing
      v-if="isAdminMailings"
      :title="2"
      :mailing="mailingSelected"
      :admin="true"
      @close="
        isAdminMailings = false
        isAdminPanel = true
      "
    />

    <add-trailer
      v-if="isAdminEditTrailer"
      :title="2"
      :admin="true"
      :trailer-to-update="trailerSelected"
      @close="
        isAdminEditTrailer = false
        isAdminPanel = true
      "
    />

    <edit-route
      v-if="isAdminEditRoute"
      :admin="true"
      @close="
        isAdminEditRoute = false
        isAdminPanel = true
      "
    />

    <edit-race
      v-if="isAdminEditRace"
      :admin="true"
      @close="
        isAdminEditRace = false
        isAdminPanel = true
      "
    />

    <new-unit-group
      v-if="isAdminUnitGroup"
      :admin="true"
      :group="unitGroupSelected"
      @close="
        isAdminUnitGroup = false
        isAdminPanel = true
      "
    />

    <update-user
      v-if="isAdminUsers"
      :admin="true"
      :user-to-update-from-admin="userToUpdateFromAdmin"
      :title="2"
      @close="
        isAdminUsers = false
        isAdminPanel = true
        userUpdated = !userUpdated
      "
    />
    <footer-menu />

    <transition name="dialog-fade">
      <create-user
        v-if="isCreatingUser"
        :title="1"
        @close="
          isCreatingUser = false
          isUser = true
        "
      />
    </transition>

    <transition name="dialog-fade">
      <update-user
        v-if="isUpdatingUser"
        :title="2"
        :user-to-update="selectedUser"
        :is-updating-myself="isUpdatingMyself"
        @closeFromTools="
          isUpdatingUser = false
          isUpdatingMyself = false
        "
        @close="closeUserModel"
        @show-history="showHistory"
      />
    </transition>

    <transition name="el-zoom-in-bottom">
      <AccessSettings v-if="isAccessSettings" @onClose="$store.commit('accessSettings/setIsAccessSettings', false)" />
    </transition>

    <transition name="el-zoom-in-bottom">
      <EventsList v-if="isEventsList" @close="$store.commit('events/setIsEventsList', false)" />
    </transition>

    <transition name="dialog-fade">
      <StateArchive v-if="isStateArchive" @close="$store.commit('archive/setOpenArchiveModal', false)" />
    </transition>

    <transition name="dialog-fade">
      <UnitsOverlay :visible="isStateArchiveEdit" class="edit-overlay" />
    </transition>

    <StateArchiveEdit v-if="isStateArchiveEdit" @close="$store.commit('archive/setOpenEdit', false)" />

    <create-geozones v-if="isCreatingGeo" @close="resetGeo" />

    <edit-geozones :spinnerLoading="spinnerLoading" v-if="isUpdatingGeo" :geozone="selectedGeozone" @close="resetGeo" @show-history="showHistory" />

    <transition name="dialog-fade">
      <notificationPopupTool v-if="isNotificationPopupTool" @show-false="notifyCellClick" @seen-all="notifySeenAll" @close="isNotificationPopupTool = false" :gottenNotifications="gottenNotifications" />
    </transition>

    <history-change v-if="isHistoryChange" :history-table="historyTable" @close="closeHistoryChange" @show-history="showHistory" />

    <transition name="dialog-fade">
      <commands-tool v-if="isCommands" :unit="commandsUnit" @close-commands="closeCommands" @create-commands="createCommands" />
    </transition>

    <transition name="dialog-fade">
      <create-commands v-if="isCreateCommands" :unit="commandsUnit" @close-create-commands="closeCreateCommands" />
    </transition>

    <transition name="dialog-fade">
      <skif-measure v-if="isMeasure" @close="isMeasure = false" />
    </transition>

    <legendForTracks v-if="isTrackLegend" :is-monitoring="isMonitoring" :unit-name="unitNameLegend" :mileage="unitMileage" />

    <transition name="dialog-fade">
      <sessions v-if="isSessions" @close="isSessions = false" />
    </transition>

    <transition name="dialog-fade">
      <feedback v-if="isFeedback" @close="isFeedback = false" />
    </transition>

    <transition name="el-fade-in">
      <import-wialon v-if="isOpenWialon" @close-wialon="isOpenWialon = false" />
    </transition>

    <transition name="el-fade-in">
      <import-kml v-if="isOpenKml" @close-kml="isOpenKml = false" />
    </transition>

    <transition name="el-fade-in">
      <monitoring-tool v-if="isActiveMonitoringTool" :is-active-monitoring-button="isActiveMonitoringButton" @active-monitoring-button="setIsActiveMonitoringButton" />
    </transition>

    <transition name="el-fade-in">
      <annotation v-if="vuexIsAnnotation" :annotation="vuexAnnotationUnit" @close="closeAnnotationModal" />
    </transition>

    <select-layers class="select-layers" @selectLayer="handlLayer" @addOverlay="overlay => $refs['leaflet-map'].addOverlay(overlay)" @removeOverlay="overlay => $refs['leaflet-map'].removeOverlay(overlay)" />

    <measure-tool class="measure-tool" @measure-toogle="measureToggle" />

    <!-- <help-tool class="help-tool" @feedback="handleFeedback" /> -->
    <chat-tool v-if="isFinishTour" class="chat-tool" />

    <transition name="el-fade-in">
      <annotation-camera v-if="isAnnotationCamera" :item="cameraItem" @show-modal-video="showModalVideo" @close-annotation-camera="closeAnnotationCamera" />
    </transition>

    <transition name="el-fade-in">
      <modal-video v-if="isShowModalVideo" :stream="videoStream" @close="isShowModalVideo = false" />
    </transition>

    <transition>
      <delete-message-modal v-if="isDeleteMessageModal" :unit-item="unitItem" @close="isDeleteMessageModal = false" />
    </transition>

    <demo-tour v-if="isDemo" @close="finishTour" />

    <helper v-if="isHelper" @close="isHelper = false" />

    <compose-report v-if="isComposeReport" :isReport="isReport" :formData="dataForComposeReport" @handlePrint="handlePrint" @close="isComposeReport = false" />

    <AddParametrs v-if="isShowAddParametrs" :unit="addParametrsUnit" @close="isShowAddParametrs = false" @get-sensors="getSensorsForParametrs" />

    <button v-if="isFinishTour" class="helper" @click="isHelper = true" type="button">Показать помощник</button>

    <Blocked v-if="isCompanyBlocked" />

    <SecuritySystem v-if="isNeedChangePassword" @close="handleSecuritySystemClose" />

    <NewsModals v-if="isShowNews" :news="getNews" @close-news-modals="isShowNews = false" />

    <ListKey v-if="isListKey" :id="idListKey" @close-list-key="isListKey = false" />

    <transition name="el-fade-in">
      <DisplaySettings v-if="isDisplaySettings" @onClose="isDisplaySettings = false" />
    </transition>

    <template v-if="getVuexReportMainTables">
      <ReportsTable
        v-for="table in getVuexReportMainTables"
        :key="table.id"
        :table="table"
        :style="reportStyles[table.id]"
        @reload-report="reloadReport"
        @setSize="setHeight"
        @show-compose-report="showComposeReport"
      />
    </template>
  </div>
</template>

<script>
import HTTP from '@/api/http'
import { companyApi, mailingsApi, notificationsApi, objectsApi, trailersApi } from '@/api'
import createMarker from '@/mixins/createMarker.js'
import newReports from '@/mixins/newReports.js'
import reporttemplate from '@/mixins/reporttemplate.js'
import getMarkerValues from '@/utils/getMarkerValues.js'
import { defaultValueDateEndDay } from '@/utils/helpers.js'
import _ from 'lodash'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { monitoringWebsocket, param } from './mixins'
import leafletImage from 'leaflet-image'
import axios from 'axios'
import ElementLocale from 'element-ui/lib/locale'
import localeEn from 'element-ui/lib/locale/lang/en.js'
import localeRu from 'element-ui/lib/locale/lang/ru-RU.js'
import localeKz from 'element-ui/lib/locale/lang/kz.js'
import { Metrika } from '@/services/metrika/metrika'
import UpdateAppVersion from '@/components/UpdateAppVersion/UpdateVersion.vue'
import { MapInstance } from '@/services/map'
import ReportsTable from '../../components/reports/reportsTable/reportsTable.vue'

export default {
  components: {
    DisplaySettings: () => import('@/components/companyControlsCenter/components/DisplaySettings/DisplaySettingsModal.vue'),
    SecuritySystem: () => import('@/components/login/SecuritySystem'),
    feedback: () => import('@/components/map/tools/feedback'),
    tools: () => import('@/components/map/tools/tools'),
    footerMenu: () => import('@/components/map/tools/footerMenu'),
    companySettings: () => import('@/components/map/tools/companySettings'),
    leafletMap: () => import('@/components/map/map'),
    mailing: () => import('@/components/mailing/list'),
    users: () => import('@/components/user/userList'),
    monitoring: () => import('@/components/monitoring/list.vue'),
    // геозоны
    geozones: () => import('@/components/geozones/list'),
    createGeozones: () => import('@/components/geozones/createForm'),
    editGeozones: () => import('@/components/geozones/editForm'),
    // отчеты
    reports: () => import('@/components/reports/reports'),
    addReporttemplate: () => import('@/components/reports/addReporttemplate.vue'),
    addTables: () => import('@/components/reports/addTables.vue'),
    editTables: () => import('@/components/reports/editTables.vue'),
    reportTable: () => import('@/components/reports/reportsTable/reportsTable'),
    PhotoReport: () => import('@/components/reports/reportsTable/PhotoReport.vue'),
    reportChart: () => import('@/components/reports/reportChart.vue'),
    // уведомления
    notifications: () => import('@/components/notifications/list.vue'),
    addNotify: () => import('@/components/notifications/addNotify'),
    notificationPopupTool: () => import('@/components/notifications/notificationPopupTool'),
    // рассылка
    addMailing: () => import('@/components/mailing/mailing'),
    // объекты
    param12: () => import('@/components/units/paramModal.vue'),
    units: () => import('@/components/units/list'),
    UnitsList: () => import('@/components/unitsNew/UnitsList.vue'),
    createUnit: () => import('@/components/units/unit'),
    createUnitEvents: () => import('@/components/events/event'),
    addLocator: () => import('@/components/locators/addLocator'),
    addTrailer: () => import('@/components/trailers/addTrailer'),
    newUnitGroup: () => import('@/components/unitsgroup/unitsgroupModal'),
    legendForTracks: () => import('@/components/map/tools/legend-for-tracks'),
    skifMeasure: () => import('@/components/map/tools/measure'),
    navigation: () => import('@/components/map/tools/navigation'),
    race: () => import('@/components/race/list'),
    createUser: () => import('@/components/user/userModalForm'),
    updateUser: () => import('@/components/user/userModalForm'),
    sessions: () => import('@/components/sessions/sessions.vue'),
    historyChange: () => import('@/components/history_change/historyChange.vue'),
    adminPanel: () => import('@/components/admin-panel/adminPanel.vue'),
    editRoute: () => import('@/components/race/routes/editRoute'),
    editRace: () => import('@/components/race/editRace'),
    editTerminal: () => import('@/components/terminal/terminalModal.vue'),
    commandsTool: () => import('@/components/commands/CommandsTool.vue'),
    createCommands: () => import('@/components/commands/CreateCommands.vue'),
    importWialon: () => import('@/components/admin-panel/components/ImportWialon.vue'),
    importKml: () => import('@/components/admin-panel/components/ImportKml.vue'),
    monitoringTool: () => import('@/components/monitoring/components/monitoringTool.vue'),
    annotation: () => import('@/components/map/tools/annotation'),
    selectLayers: () => import('@/components/map/tools/selectLayers'),
    measureTool: () => import('@/components/map/tools/measureTool'),
    helpTool: () => import('@/components/map/tools/HelpTools.vue'),
    annotationCamera: () => import('@/components/monitoring/components/annotationCamera.vue'),
    modalVideo: () => import('@/components/monitoring/components/ModalVideo.vue'),
    deleteMessageModal: () => import('@/components/reports/reportsTable/modals/DeleteMessageModal.vue'),
    demoTour: () => import('@/components/demo/DemoTour.vue'),
    chatTool: () => import('@/components/chat/ChatTool.vue'),
    helper: () => import('@/components/helper/Helper.vue'),
    composeReport: () => import('@/components/reports/ComposeReport.vue'),
    Blocked: () => import('./components/Blocked.vue'),
    AddParametrs: () => import('@/components/add-parametrs/AddParametrs.vue'),
    NewsModals: () => import('@/components/modals/NewsIntegrator'),
    ListKey: () => import('@/components/modals/ListKey.vue'),
    AccessSettings: () => import('@/components/companyControlsCenter/components/AccessSettings/accessSettings.vue'),
    EventsList: () => import('@/components/events/list.vue'),
    StateArchive: () => import('@/components/unitsNew/components/Objects/Sections/Shifts/StateArchive.vue'),
    StateArchiveEdit: () => import('@/components/unitsNew/components/Objects/Sections/Shifts/StateArchiveEdit.vue'),
    UnitsOverlay: () => import('@/components/unitsNew/components/UnitsOverlay'),
    UpdateAppVersion,
    ReportsTable: () => import('@/components/reports/components/ReportsTable.vue'),
  },
  mixins: [newReports, reporttemplate, monitoringWebsocket, param, createMarker],
  data() {
    return {
      map: null,
      previousNotifications: [],
      isDisplaySettings: false,
      isNewUser: false,
      unitNameLegend: '',
      unitMileage: '',
      isFeedback: false,
      unitFromMonitoring: null,
      reportPhotos: [],
      rowForPhoto: '',
      isPhotoShow: false,
      isAdminPanelGeoCLosed: false,
      isCompanyBlocked: false,
      shouldOpenUsersList: false,
      eventToUpdate: '',
      isAdminCompanySettings: false,
      companysettings: '',
      isAdminGeozone: false,
      isAdminNotification: false,
      isAdminFromReportsTemplate: false,
      isAdminReportTemplate: false,
      isAdminMailings: false,
      isAdminEditTrailer: false,
      isAdminEditRace: false,
      isAdminEditRoute: false,
      isAdminUnitGroup: false,
      isAdminUpdateUnits: false,
      isAdminUsers: false,
      userUpdated: false,
      userToUpdateFromAdmin: '',
      isGeo: false,
      isUser: false,
      isReport: false,
      isNotify: false,
      isUnits: false,
      isMonitoring: false,
      isAdminPanel: false,
      isMailing: false,
      isCreatingMailing: false,
      isUpdatingMailing: false,
      mailingSelected: {},
      isCreatingGroup: false,
      isUpdatingGroup: false,
      isCreatingUnit: false,
      isUpdatingTerminals: false,
      updateItem: null,
      terminalsData: '',
      isUpdatingUnit: false,
      isCreatingUnitEvents: false,
      isUpdatingUnitEvents: false,
      isCreatingTrailer: false,
      isUpdatingTrailer: false,
      unitGroupSelected: {},
      unitSelected: {},
      geozone: null,
      geometry: [],
      geozonesAll: [],
      radius: null,
      visible: false,
      isPopoverVisible: false,
      isCreatingGeo: false,
      isUpdatingGeo: false,
      spinnerLoading: true,
      isMonitoringButton: false,
      selectedGeozone: {},
      selectedGeozoneAdmin: {},
      gottenNotifications: [],
      notificationSelected: {},
      afterUpdateUnit: '',
      selectedUser: {},
      isCreatingNotify: false,
      isUpdatingNotify: false,
      isNotificationPopupTool: false,
      isTrackLegend: false,
      isMeasure: false,
      isInModule: false,
      isRace: false,
      isCreatingUser: false,
      isUpdatingUser: false,
      isUpdatingMyself: false,
      isUnitsOrReport: false,
      isUnitsOrMonitoring: false,
      isSessions: false,
      isNavigation: true,
      isHistoryChange: false,
      formDataHistory: null,
      toHistoryFrom: null,
      historyTable: {
        list: [
          {
            created: '2020-11-11 15:55:33',
            user: {
              id: '5551b5bd-b4d7-4f99-a166-c9ecdabccee0',
              name: 'user1',
              email: 'user1@mail.ru'
            },
            objects: 'company',
            operation: 'PUT',
            object_id: '11e541f5-3b57-4968-a428-eb13dde6ede0',
            msg: 'Пользователь user1 обновил настройки компании'
          }
        ],
        max_rows: 1
      },
      isCommands: false,
      commandsUnit: null,
      isCreateCommands: false,
      updateCompanySettings: null,
      isOpenWialon: false,
      isOpenKml: false,
      tracksDone: null,
      circleGroupTracks: null,
      isActiveMonitoringTarget: false,
      isMonitoringTarget: false,
      isActiveMonitoringTool: false,
      isActiveMonitoringButton: false,
      isMonitoringManipulation: false,
      fromMonitoring: false,
      markerCluster: null,
      markerGroup: null,
      markerGroupUnits: null,
      isCreatingLocator: false,
      isUpdatingLocator: false,
      isFromMonitoring: false,
      activeTabUnits: '',
      lastCoords: [],
      isAnnotationCamera: false,
      cameraItem: null,
      isOpenedAnnotation: '',
      isShowModalVideo: false,
      videoStream: null,
      editedObjectId: '',
      isDeleteMessageModal: false,
      unitItem: null,
      isDemo: false,
      isDemoAccount: false,
      isHelper: false,
      isFinishTour: false,
      editFromMonitoring: false,
      isComposeReport: false,
      dataForComposeReport: [],
      isShowAddParametrs: false,
      addParametrsUnit: null,
      isRefreshReport: false,
      activeWindow: null,
      isShowNews: false,
      isListKey: false,
      idListKey: ''
    }
  },

  computed: {
    ...mapGetters('login', ['changingCompany', 'role', 'isOnline', 'notRole', 'activeCompanyId']),
    ...mapGetters({
      vuexIsAnnotation: 'monitoringNew/vuexIsAnnotation',
      vuexAnnotationUnit: 'monitoringNew/vuexAnnotationUnit',
      vuexActiveArrow: 'monitoringNew/vuexActiveArrow',
      vuexActiveEye: 'monitoringNew/vuexActiveEye',
      vuexMarkersOnMap: 'monitoringNew/vuexMarkersOnMap',
      vuexMarkersUnitOnMap: 'monitoringNew/vuexMarkersUnitOnMap',
      formattedCurrentTime: 'login/formattedCurrentTime',
      getUnit: 'units/unit',
      getNews: 'properties/getNews',
      getIsVuexUpdateAppVersion: 'notifications/getIsVuexUpdateAppVersion',
      getVuexReportMainTables: 'reports/getVuexReportMainTables',
    }),
    ...mapGetters('company', ['formatTime']),
    ...mapState('notifications', {
      savedNotifications: state => state.savedNotifications
    }),
    ...mapState('reports', {
      drawenMakers: state => state.drawenMakers,
      drawenArrows: state => state.drawenArrows,
      chartBase64: state => state.chartBase64
    }),
    ...mapState('login', {
      me: state => state.me,
      selectedModule: state => state.selectedModule,
      isNeedChangePassword: state => state.isNeedChangePassword
    }),
    isAccessSettings() {
      return this.$store.getters['accessSettings/getIsAccessSettings']
    },
    isEventsList() {
      return this.$store.getters['events/getIsEventsList']
    },
    isStateArchive() {
      return this.$store.getters['archive/getOpenArchiveModal']
    },
    isStateArchiveEdit() {
      return this.$store.getters['archive/getOpenEdit']
    },
    notificationIds() {
      return this.gottenNotifications.map(notify => notify.id)
    },
    ...mapState('properties', { customFields: state => state.customFields }),
    statusCompany() {
      return this.me.active_company.billing.company_status !== 'BLOCKED' && this.me.active_company.billing.company_status !== ''
    }
  },
  // test
  watch: {
    isEventsList(val) {
      if (val) {
        this.isUnits = false
        return
      }
      this.isUnits = true
    },
    isStateArchive(val) {
      if (val) {
        this.isUnits = false

        return
      }
      this.isUnits = true
    },
    successInvite() {
      this.$store.commit('login/SET_CHANGE_PASSWORD', false)
    },
    isOnline(val) {
      if (val) {
        this.$store.dispatch('units/GET_UNITS')
      }
    },
    changingCompany() {
      this.isGeo = false
      this.isUser = false
      this.isReport = false
      this.isNotify = false
      this.isUnits = false
      this.isMonitoring = false
      this.isAdminPanel = false
      this.isMailing = false
      this.isCreatingMailing = false
      this.isUpdatingMailing = false
      this.isOpenWialon = false
      this.isOpenKml = false
      this.isCreatingGroup = false
      this.isUpdatingGroup = false
      this.isCreatingUnit = false
      this.isUpdatingUnit = false
      this.isCreatingUnitEvents = false
      this.isUpdatingUnitEvents = false
      this.isCreatingTrailer = false
      this.isCreatingLocator = false
      this.isUpdatingTrailer = false
      this.isUpdatingLocator = false
      this.isPopoverVisible = false
      this.isCreatingGeo = false
      this.isUpdatingGeo = false
      this.isNotificationPopupTool = false
      this.isTrackLegend = false
      this.isMeasure = false
      this.isInModule = false
      this.isRace = false
      this.isCreatingUser = false
      this.isUpdatingUser = false
      this.isUpdatingMyself = false
      this.isUnitsOrReport = false
      this.isUnitsOrMonitoring = false
      this.isSessions = false
      this.isHistoryChange = false
      this.unitFromMonitoring = null
    },
    isAdminFromReportsTemplate(val) {
      if (val === true) {
        this.isAdminPanel = true
      }
    },
    spinnerLoading(val) {
      if (val) {
        if (this.isCreatingGeo) {
          this.resetGeo(false)
        }
        if (this.isNotificationPopupTool) {
          this.isNotificationPopupTool = false
        }
        if (this.isGeo) {
          this.isGeo = false
        }
        if (this.isCreatingNotify) {
          this.isCreatingNotify = false
        }
        if (this.isUpdatingNotify) {
          this.isUpdatingNotify = false
        }
        if (this.isNotify) {
          this.isNotify = false
        }
        if (this.isReport) {
          this.isReport = false
        }
        if (this.isUnits) {
          this.isUnits = false
        }
        if (this.isMonitoring) {
          this.isMonitoring = false
        }
        this.statusIcons = {}
      }
    },
    '$store.state.monitoringNew.monitoringList': {
      deep: true,
      handler(val) {
        // обновление кгда открыта аннотация и приходят данные с веб сокета
        if (this.vuexIsAnnotation) {
          val.forEach(el => {
            if (this.vuexAnnotationUnit.id === el.unit_id) {
              const updateUnitForAnnotation = this.vuexAnnotationUnit
              updateUnitForAnnotation.columns = el.columns
              this.setVuexAnnotationUnit(updateUnitForAnnotation)
            }
          })
        }
        // если мониторинг закрыт а обьекты на карте есть
        if ((this.vuexActiveArrow.length || this.vuexActiveEye.length) && !this.isMonitoring) {
          this.recalcStatus(val)
        }
      }
    },
    'me.lang.key': function (val) {
      const langHtml = window.document.querySelector('html')
      langHtml.setAttribute('lang', val)
    },
    getNews(val) {
      if (val) {
        this.isShowNews = true
      }
    }
  },

  methods: {
    ...mapActions({
      getTerminals: 'terminal/getTerminals',
      getPorts: 'ports/getPorts',
      CLEAR_EYE_ARROW: 'monitoringNew/CLEAR_EYE_ARROW',
      getFormatTime: 'company/getFormatTime'
    }),
    ...mapMutations('login', ['IS_ONLINE']),
    ...mapMutations('map', ['CLEAR_MARKER_NOTIFY']),
    ...mapMutations({
      setVuexAnnotationUnit: 'monitoringNew/setVuexAnnotationUnit',
      setVuexIsAnnotation: 'monitoringNew/setVuexIsAnnotation',
      clearVuexActiveEye: 'monitoringNew/clearVuexActiveEye',
      clearVuexActiveArrow: 'monitoringNew/clearVuexActiveArrow',
      clearVuexMarkersOnMap: 'monitoringNew/clearVuexMarkersOnMap',
      addVuexMarkersUnitOnMap: 'monitoringNew/addVuexMarkersUnitOnMap'
    }),
    ...mapMutations('reports', ['CLEAR_TREK']),

    showListKey(id) {
      this.isListKey = true
      this.idListKey = id
    },

    closeAnnotationModal() {
      this.setVuexIsAnnotation(false)

      this.isOpenedAnnotation = 'closed'
    },

    updateReportTemplates(updatedTables) {
      this.reporttemplateSelected.template_tables = updatedTables

      this.$store.dispatch('reports/UPDATE_TEMPLATE', this.reporttemplateSelected)
    },

    helperTrackingMarkers: _.throttle(function (array) {
      if (!array.length) return
      if (this.vuexActiveArrow.length > 1) {
        const bounds = L.latLngBounds(array)
        MapInstance.fitBounds(bounds)
      } else {
        MapInstance.setView(array[0], this.zoom, {})
      }
    }, 1000),

    setLastCoords(coords) {
      this.lastCoords = coords
    },

    setMarkerCluster(markerCluster) {
      this.markerCluster = markerCluster
    },

    setMarkerGroup(markerGroup) {
      this.markerGroup = markerGroup
    },

    setMarkerGroupUnits(markerGroupUnits) {
      this.markerGroupUnits = markerGroupUnits
    },

    clearAllLayers() {
      this.isClearAllLayers = true
    },

    setFromMonitoring(val) {
      this.fromMonitoring = val
    },

    setIsActiveMonitoringButton() {
      this.isActiveMonitoringButton = true

      if (!this.isMonitoring) {
        this.helperTrackingMarkers(this.lastCoords)
      }

      this.setMonitoringManipulation()
    },

    setMonitoringManipulation() {
      this.isMonitoringManipulation = true
      setTimeout(() => {
        this.isMonitoringManipulation = false
      }, 1500)
    },

    editGroupFromMonitoring(item) {
      this.fromMonitoring = true
      this.isMonitoring = false
      this.isUpdatingGroup = true
      this.unitGroupSelected = {
        id: item.group_id,
        name: item.group_name,
        units: item.units,
        users: []
      }
    },

    isActiveArrow(isArrow) {
      this.isActiveMonitoringTool = isArrow
      this.isActiveMonitoringButton = true
    },

    handleShowLegend(data) {
      this.isTrackLegend = data.isShowLegend
      const currentUnit = this.getUnit(data.unitName)
      this.unitNameLegend = (currentUnit && currentUnit.name) || data.unitName

      this.unitMileage = data.sum_distance
    },

    setMonitoringButton(val) {
      setTimeout(() => {
        this.isMonitoringButton = val
        this.isMonitoringButtonArrow = val
      }, 100)
    },

    setActivateMonitoringButton(val) {
      this.isMonitoringButtonArrow = val
      this.isMonitoringButton = val
    },

    showGroupTrack(tracks) {
      this.tracksDone = tracks
    },

    propsCircleGroupTrack(circle) {
      this.circleGroupTracks = circle
      this.$store.dispatch('reports/HANDLE_CIRCLEGROUPTRACK', circle)
    },

    openWialon(isOpen) {
      this.isOpenWialon = isOpen
    },

    openKml(isOpen) {
      this.isOpenKml = isOpen
    },

    handleFeedback() {
      this.isFeedback = true
    },

    saveSettingsChangeCompany(unit) {
      this.updateCompanySettings = unit
    },

    closeCommands() {
      this.isCommands = false
      this.isMonitoring = true
    },

    closeCreateCommands() {
      this.isCreateCommands = false
      this.isCommands = true
    },

    createCommands() {
      this.isCommands = false
      this.isCreateCommands = true
    },

    openCommands(unit) {
      // this.isMonitoring = false
      this.isCommands = true
      this.commandsUnit = unit
    },

    toMonitoring() {
      this.isReport = false
      this.isMonitoring = true
    },

    toReport(prop) {
      if (prop.fromMonitoring) this.isFromMonitoring = prop.fromMonitoring
      this.isMonitoring = false
      this.isReport = true
    },

    openReport() {
      this.isMonitoring = false
      this.isReport = true
    },

    showReportTable(event) {
      this.isShowReportTable = true
      this.isFromMonitoring = event
    },

    updateStatus() {
      if (typeof window.navigator.onLine === 'undefined') {
        this.IS_ONLINE(true)
      } else {
        this.IS_ONLINE(window.navigator.onLine)
      }
    },

    handleClosePhoto(index) {
      this.reportPhotos.splice(index, 1)
    },

    handlFotoRow(row) {
      this.reportPhotos = []

      // MultiPhoto
      // let index = this.reportPhotos.findIndex((x) => x.index === row.index)
      // index === -1
      //   ? this.reportPhotos.push(row)
      //   : console.log('row already exist')

      this.reportPhotos.push(row)
      this.reportPhotos.length = 1
    },
    handlLogout() {
      this.$notify.closeAll()
      this.isCompanyBlocked = false
    },
    closeNotification() {
      this.gottenNotifications = []

      this.$nextTick(() => {
        this.$store.commit('units/RESET_FILTRED_UNITS')
      })

      localStorage.setItem('monitoringMarkers', JSON.stringify([]))
      this.CLEAR_EYE_ARROW()
      this.CLEAR_MARKER_NOTIFY()

      this.isUpdatingTerminals = false
      this.$store.commit('map/COMPANY_CHANGE')
      this.$notify.closeAll()
      this.isCompanyBlocked = false

      this.closeAnnotationModal()
    },
    closeDialogTerminal(e) {
      this.isUpdatingTerminals = false
      if (e && Object.keys(e).length) {
        this.updateItem = e.data
      }
    },
    closeUserModel() {
      this.isUpdatingUser = false

      if (!this.shouldOpenUsersList) {
        this.isUser = true
      } else {
        this.isUser = false
      }
    },
    async handlLayer(layer) {
      setTimeout(async () => {
        const leafletMap = this.$refs['leaflet-map']
        if (leafletMap) {
          await leafletMap.selectLayer(layer)
        } else {
          console.error('No $refs[leaflet-map]')
        }
      }, 100)
    },
    closeAdminGeozone() {
      this.isAdminGeozone = false
      this.isAdminPanelGeoCLosed = !this.isAdminPanelGeoCLosed
    },
    async showGeozoneAdmin(val) {
      this.selectedGeozoneAdmin = val
      this.isAdminGeozone = true
      this.updateGeozone(val, true)
    },
    showNotificationAdmin(val) {
      this.isAdminNotification = true
      this.isAdmin = false
      this.notificationSelected = val
    },
    showReportsTemplateAdmin(templateId) {
      this.isAdminReportTemplate = true
      this.updateReporttemplate({ ...templateId, isAdmin: true })
    },
    showMailingsAdmin(mailingId) {
      mailingsApi.getMailingById(mailingId, res => {
        this.mailingSelected = res.data
        this.isAdminMailings = true
      })
    },
    showTrailerAdmin(trailerId) {
      trailersApi.getTrailer(trailerId, res => {
        this.trailerSelected = res.data
        this.isAdminEditTrailer = true
      })
    },
    showUsersAdmin(userId) {
      usersApi.getUserById(userId, res => {
        this.userToUpdateFromAdmin = res.data
        this.isAdminUsers = true
      })
    },
    showCompanyAdmin(companyId) {
      companyApi.companyForAdminPanel(companyId, ({ data }) => {
        this.companysettings = [data]
        this.isAdminCompanySettings = true
      })
    },
    showUnitGroupAdmin(unitsGroup) {
      objectsApi.getGroupObjectId(unitsGroup.id, res => {
        this.unitGroupSelected = JSON.parse(JSON.stringify(res.data))
        this.$store.commit('units/SET_UNITS', res.data.units)
        this.isAdminUnitGroup = true
      })
    },
    showRouteAdmin(route) {
      this.$store.dispatch('routes/GET_ROUTE_ID', route)
      this.isAdminEditRoute = true
    },
    showTripAdmin(trip) {
      this.$store.dispatch('races/GET_RARCE_ID', trip)
      this.isAdminEditRace = true
    },
    showTerminalsAdmin(terminals) {
      this.terminalsData = terminals
      this.updateItem = null
      this.isUpdatingTerminals = true
    },
    createUnit() {
      this.activeTabUnits = 'units'
      this.isCreatingUnit = true
      this.isUnits = false
      this.unitSelected = {
        name: '',
        type: {
          key: 'car'
        },
        terminal_type: {
          key: ''
        },
        custom_fields: JSON.parse(
          JSON.stringify(
            this.customFields.map(obj => ({
              value: obj.default_value.key || obj.default_value,
              key: obj.key,
              name: obj.name,
              group: obj.group,
              show_in_monitoring: false
            }))
          )
        ),
        services: [],
        sensors: [],
        phonenumber: '',
        phonenumber2: '',
        imei: '',
        icon: {
          key: 'car2_red'
        }
      }
    },
    async showReports() {
      if (this.isUnits) this.isUnits = false
      if (this.isReport) {
        this.hideReports()
      }
      this.checkActiveWindow()
      this.isReport = true
      Metrika.Send('OPEN_MODULE_REPORTS')
      await this.$nextTick()
      this.isInModule = true
    },
    async hideReports() {
      this.isInModule = false
      await this.$nextTick()
      this.isReport = false
      this.isFromMonitoring = false
    },
    async showMonitoring() {
      if (this.isUnits) this.isUnits = false
      if (this.isMonitoring) {
        this.hideMonitoring()
      }
      this.checkActiveWindow()
      this.isMonitoring = true
      Metrika.Send('OPEN_MODULE_MONITORING')
      await this.$nextTick()
      this.isInModule = true
    },
    async showRace() {
      if (this.isUnits) this.isUnits = false
      if (this.isRace) {
        this.hideRace()
      }
      this.checkActiveWindow()
      this.isRace = true
      Metrika.Send('OPEN_MODULE_TRIPS')
      await this.$nextTick()
      this.isInModule = true
    },
    async hideRace() {
      this.isNavigation = true
      await this.$nextTick()
      this.isRace = false
    },
    async hideMonitoring() {
      this.isInModule = false
      await this.$nextTick()
      this.isMonitoring = false
    },
    async showObjects() {
      if (this.isUnits) {
        this.hideObjects()
      }
      this.checkActiveWindow()
      Metrika.Send('OPEN_MODULE_OBJECTS')
      this.isUnits = true
      await this.$nextTick()
      this.isInModule = true
    },
    async hideObjects() {
      this.isInModule = false
      await this.$nextTick()
      this.isUnits = false
      this.activeTabUnits = 'units'
    },
    backToMonitoring() {
      this.hideObjects()

      this.isMonitoring = true
    },
    async showMailing() {
      if (this.isUnits) this.isUnits = false
      if (this.isMailing) {
        this.hideMailing()
      }
      this.checkActiveWindow()
      this.isMailing = true
      Metrika.Send('OPEN_MODULE_MAILING_LIST')
      await this.$nextTick()
      this.isInModule = true
    },
    async hideMailing() {
      this.isInModule = false
      await this.$nextTick()
      this.isMailing = false
    },
    async showNotification() {
      if (this.isUnits) this.isUnits = false
      if (this.isNotify) {
        this.hideNotification()
      }
      this.checkActiveWindow()
      this.isNotify = true
      Metrika.Send('OPEN_MODULE_NOTIFICATIONS')
      await this.$nextTick()
      this.isInModule = true
    },
    async hideNotification() {
      this.isInModule = false
      await this.$nextTick()
      this.isNotify = false
    },
    async showGeozone() {
      if (this.isUnits) this.isUnits = false
      if (this.isGeo) {
        this.hideGeozone()
      }
      this.checkActiveWindow()
      this.isGeo = true
      Metrika.Send('OPEN_MODULE_GEOZONES')
      await this.$nextTick()
      this.isInModule = true
    },
    async hideGeozone() {
      this.isInModule = false
      await this.$nextTick()
      this.isGeo = false
    },
    async showUser() {
      if (this.isUnits) this.isUnits = false
      if (this.isUser) {
        this.hideUser()
      }
      this.checkActiveWindow()
      this.isUser = true
      Metrika.Send('OPEN_MODULE_USERS')
      this.activeWindow = 'users'
      await this.$nextTick()
      this.isInModule = true
    },
    async hideUser() {
      this.isInModule = false
      await this.$nextTick()
      this.isUser = false
    },

    checkActiveWindow() {
      const variablesToToggle = ['isUser', 'isGeo', 'isNotify', 'isUnits', 'isMonitoring', 'isMailing', 'isReport', 'isRace']

      variablesToToggle.forEach(item => {
        if (this[item]) {
          this[item] = false
        }
      })
    },
    closeHistoryChange() {
      this.isHistoryChange = false
      switch (this.toHistoryFrom.objects) {
        case 'users':
          if (!this.toHistoryFrom.objectId) this.isUser = true
          else this.isUpdatingUser = true
          // if (this.toHistoryFrom.objectId) {
          //   this.isUpdatingUser = true
          // } else {
          //   this.isUser = true
          // }
          break
        case 'units':
          if (this.toHistoryFrom.objectId) {
            // this.isUpdatingUnit = true
            this.isUnits = true
          } else {
            this.isUnits = true
          }
          break
        case 'report_templates':
          if (this.toHistoryFrom.objectId) {
            this.isReport = true
          } else {
            this.isReport = true
          }
          break
        case 'geozones':
          this.isGeo = false
          break
        case 'routes':
          this.isRace = true
          break
        case 'trips':
          this.isRace = true
          break
        case 'trailers':
          this.isUpdatingTrailer = true
          break
        case 'locators':
          this.isUpdatingLocator = true
          break
        case 'mailings':
          this.isMailing = true
          break
        default:
          break
      }
    },

    showHistory({ objects, objectId, formData, firstRow }) {
      const dates = defaultValueDateEndDay()

      if (formData) {
        companyApi.getHistory(
          {
            ...this.formDataHistory,
            from: formData.dates[0],
            to: formData.dates[1],
            first_row: firstRow,
            sortField: formData.sortField,
            sortDesc: formData.sortDesc,
            value: formData.value
          },
          res => {
            this.historyTable = res.data
          },
          error => {
            this.$showError(error.response.data.message)
          }
        )
        return
      }
      if (objectId) {
        this.formDataHistory = {
          from: dates[0],
          to: dates[1],
          first_row: firstRow,
          max_rows: 100,
          object_id: objectId,
          objects
        }
        companyApi.getHistory(
          this.formDataHistory,
          res => {
            switch (objects) {
              case 'users':
                this.isUser = false
                break
              case 'units':
                this.isUpdatingUnit = false
                this.isCreatingUnitEvents = false
                this.isUnits = false
                break
              case 'geozones':
                this.isGeo = false
                break
              case 'routes':
                this.isMonitoring = false
                break
              case 'trips':
                this.isMonitoring = false
                break
              case 'trailers':
                this.isMonitoring = false
                break
              case 'mailings':
                this.isMonitoring = false
                break
              case 'report_templates':
                this.isReport = false
                break
              default:
                break
            }
            this.isHistoryChange = true
            this.toHistoryFrom = { objects, objectId }
            this.historyTable = res.data
          },
          error => {
            this.$showError(error.response.data.message)
          }
        )
      } else {
        this.formDataHistory = {
          from: dates[0],
          to: dates[1],
          first_row: firstRow,
          max_rows: 100,
          objects
        }
        companyApi.getHistory(
          this.formDataHistory,
          res => {
            switch (objects) {
              case 'users':
                this.isUser = false
                break
              case 'units':
                this.isUnits = false
                break
              case 'geozones':
                this.isGeo = false
                break
              case 'routes':
                this.isNavigation = true
                this.isRace = false
                break
              case 'trips':
                this.isNavigation = true
                this.isRace = false
                break
              case 'trailers':
                this.isCreatingTrailer = false
                break
              case 'locators':
                this.isCreatingLocator = false
                break
              case 'mailings':
                this.isMailing = false
                break
              case 'notifications':
                this.isMonitoring = false
                break
              case 'report_templates':
                this.isReport = false
                break
              default:
                break
            }
            this.isMonitoring = false
            this.isHistoryChange = true
            this.toHistoryFrom = { objects }
            this.historyTable = { ...res.data, objects }
          },
          error => {
            this.$showError(error.response.data.message)
          }
        )
      }
    },

    updateMailing(mailingId) {
      mailingsApi.getMailingById(
        mailingId,
        response => {
          this.mailingSelected = JSON.parse(JSON.stringify(response.data))
          this.isUpdatingMailing = true
        },
        error => {
          this.$showError(error.response.data.message)
        }
      )
    },
    updateTrailer(trailerId) {
      this.activeTabUnits = 'trailers'
      trailersApi.getTrailer(
        trailerId,
        response => {
          this.trailerSelected = JSON.parse(JSON.stringify(response.data))
          this.isUpdatingTrailer = true
        },
        error => {
          this.$showError(error.response.data.message)
        }
      )
    },
    updateUnit({ unitId, fromModule }) {
      this.afterUpdateUnit = fromModule
      this.activeTabUnits = 'units'

      if (unitId) {
        this.editedObjectId = unitId
        this.isUnits = true
        this.isMonitoring = false
      }
    },
    updateUnitEvents({ unitId, fromModule, isEdit, event }) {
      this.afterUpdateUnit = fromModule
      objectsApi.getObject(
        unitId,
        response => {
          this.unitSelected = JSON.parse(JSON.stringify(response.data))
          if (isEdit) {
            this.eventToUpdate = event
            this.isUpdatingUnitEvents = true
          } else {
            this.isCreatingUnitEvents = true
          }
          if (fromModule === 'units') {
            this.isUnits = false
          } else if (fromModule === 'monitoring') {
            this.isMonitoring = false
          } else {
            this.isReport = false
          }
        },
        error => {
          this.$showError(error.response.data.message)
        }
      )
    },
    closeUpdateUnit(status) {
      if (status) {
        this.isUpdatingUnitEvents = false
        this.isUnits = true
      }
      this.isUpdatingUnit = false
      this.isCreatingUnitEvents = false
      if (this.afterUpdateUnit === 'units') {
        this.isUnits = true
      } else if (this.afterUpdateUnit === 'monitoring') {
        this.isMonitoring = true
      } else if (this.afterUpdateUnit === 'admin-panel') {
        this.isAdminPanel = true
      } else {
        this.isReport = true
      }
    },
    closeUpdateUnitsgroup() {
      this.activeTabUnits = 'unitsgroups'
      if (this.fromMonitoring) {
        this.isUpdatingGroup = false
        this.isMonitoring = true
      } else {
        this.isUpdatingGroup = false
        if (this.isUnitsOrReport) {
          this.isUnits = true
        } else {
          this.isReport = true
        }
      }
    },
    updateUnitsgroup({ unitsgroup, isUnits }) {
      this.activeTabUnits = 'unitsgroups'
      this.isUnitsOrReport = isUnits
      this.unitGroupSelected = JSON.parse(JSON.stringify(unitsgroup))
      this.isUpdatingGroup = true
      if (isUnits) {
        this.isUnits = false
      } else {
        this.isReport = false
      }
    },

    measureToggle() {
      if (this.isMeasure) {
        this.isMeasure = false
        this.$store.commit('measure/MEASURE_CHANGE', false)
      } else {
        this.isMeasure = true
        this.$store.commit('measure/MEASURE_CHANGE', true)
      }
    },

    getCacheNotification() {
      const notificationStorage = localStorage.getItem('notifications')
      if (notificationStorage) {
        const savedNotifications = JSON.parse(notificationStorage)
        let date = Object.keys(savedNotifications)[0]
        if ((new Date() - new Date(new Date(date).toDateString())) / 3.6e6 > 48) {
          date = new Date()
          localStorage.setItem('notifications', JSON.stringify({ [date]: [] }))
          this.$store.commit('notifications/SAVED', [])
        } else {
          this.$store.commit('notifications/SAVED', Object.values(savedNotifications)[0])
        }
      } else {
        const currentTime = new Date()
        localStorage.setItem('notifications', JSON.stringify({ [currentTime]: [] }))
        this.$store.commit('notifications/SAVED', [])
      }
    },
    notifyCellClick(id) {
      const index = this.gottenNotifications.findIndex(el => el.id === id)
      if (!this.gottenNotifications[index].isSeen) {
        this.gottenNotifications[index].isSeen = true
        const savedNotifications = JSON.parse(localStorage.getItem('notifications'))
        const date = Object.keys(savedNotifications)[0]
        const notifyIds = [...Object.values(savedNotifications)[0], this.gottenNotifications[index].id]
        localStorage.setItem('notifications', JSON.stringify({ [date]: notifyIds }))
        this.$store.commit('notifications/SAVED', notifyIds)
      }
      const notifyId = this.gottenNotifications[index].id
      this.$refs['leaflet-map'].notifyCellClick(notifyId, this.gottenNotifications[index])
    },
    notifySeenAll() {
      const notifiesArr = []
      this.gottenNotifications = this.gottenNotifications.map(notify => {
        if (!notify.isSeen) {
          notifiesArr.push(notify.id)
        }
        return { ...notify, isSeen: true }
      })
      const savedNotifications = JSON.parse(localStorage.getItem('notifications'))
      const date = Object.keys(savedNotifications)[0]
      const notifyIds = [...Object.values(savedNotifications)[0], ...notifiesArr]
      localStorage.setItem('notifications', JSON.stringify({ [date]: notifyIds }))
      this.$store.commit('notifications/SAVED', notifyIds)
    },

    newNotification(notifications) {
      const areSameNotifications = JSON.stringify(notifications) === JSON.stringify(this.previousNotifications)

      if (areSameNotifications) {
        return
      }
      this.previousNotifications = [...notifications]

      if (notifications.length === 1 && !this.savedNotifications.includes(notifications[0].id)) {
        if (notifications[0].sound) {
          notificationsApi.getNotificationSound(
            `${notifications[0].sound}.mp3`,
            response => {
              const mp3 = new Blob([response.data], { type: 'audio/mp3' })
              const url = window.URL.createObjectURL(mp3)
              const audio = new Audio(url)
              audio.load()
              audio.play()
            },
            error => {
              console.log('error', error)
            }
          )
        }

        this.$showNotification(notifications[0].templateName, notifications[0].msg, notifications[0].id)
      }
      // Step 1: Create an array of new notifications, marking them as seen if needed (based on saved notifications id's in local storage)
      const savedNotifications = JSON.parse(localStorage.getItem('notifications'))
      const seenNotificationIds = savedNotifications ? Object.values(savedNotifications)[0] : []
      // Update notifications list with the isSeen property
      const updatedNotifications = notifications.map(notify => {
        const isSeen = seenNotificationIds.includes(notify.id) || this.savedNotifications.includes(notify.id)
        return { ...notify, isSeen }
      })

      // Step 2: Combine updated notifications with the existing notifications
      const existingNotificationsSet = new Set(this.gottenNotifications.map(notify => notify.id))
      const combinedNotifications = updatedNotifications.filter(notify => !existingNotificationsSet.has(notify.id))

      // Step 3: Merge new notifications with existing ones
      this.gottenNotifications = [...combinedNotifications, ...this.gottenNotifications.filter(notify => existingNotificationsSet.has(notify.id))]
    },

    setWebsocket() {
      const monitoringStorage = localStorage.getItem('monitoringUnits')
      if (monitoringStorage) {
        const unitIds = JSON.parse(monitoringStorage)
        if (unitIds[this.activeCompanyId]) {
          this.$store.commit('monitoring/CACHE_MONITORING', unitIds[this.activeCompanyId])
        } else {
          localStorage.setItem('monitoringUnits', JSON.stringify({ ...unitIds, [this.activeCompanyId]: [] }))
        }
      } else {
        localStorage.setItem('monitoringUnits', JSON.stringify({ [this.activeCompanyId]: [] }))
      }
      this.clearLastpoints()
      const leafletMap = this.$refs['leaflet-map']
      if (leafletMap) {
        this.$refs['leaflet-map'].startWebSocket()
      } else {
        setTimeout(() => {
          console.error('No ref leaflet-map, recursive restart setWebsocket 300ms')
          this.setWebsocket()
        }, 300)
      }
    },
    async updateNotification(row) {
      try {
        const response = await HTTP.get(`/notifications?ids=${row.id}`, {
          needAuth: true
        })
        this.notificationSelected = response.data
        this.isUpdatingNotify = true
        this.isNotify = false
      } catch (error) {
        console.log(error)
      }
    },
    updateGeozone(geozone, isAdmin) {
      this.$store.commit('geozones/FITBOUNDS_TO_GEOZONE', geozone.id)
      this.$store.commit('geozones/START_EDITING', geozone.id)
      this.selectedGeozone = JSON.parse(JSON.stringify(geozone))

      if (!isAdmin && (this.notRole || this.role === 'EDITOR')) {
        this.isUpdatingGeo = true
        this.isGeo = false
      }
    },
    updateUser(user, isUpdatingMyself, shouldOpenUsersList) {
      this.shouldOpenUsersList = !!shouldOpenUsersList
      this.isUpdatingMyself = isUpdatingMyself
      this.selectedUser = JSON.parse(JSON.stringify(user))
      this.isUpdatingUser = true
    },
    resetGeo(showGeo = true) {
      if (this.isCreatingGeo) {
        this.$store.commit('geozones/END_DRAWING')
        this.isCreatingGeo = false
      } else {
        this.isUpdatingGeo = false
      }
      this.isGeo = showGeo
    },
    getMapView() {
      const mapZoom = localStorage.getItem('mapZoom')
      if (mapZoom) {
        this.$store.commit('map/SET_ZOOM', mapZoom)
      }
      const mapCenter = localStorage.getItem('mapCenter')
      if (mapCenter) {
        this.$store.commit('map/SET_CENTER', JSON.parse(mapCenter))
      }
    },
    handleSecuritySystemClose() {
      this.getAllRequest()
    },

    async getAllRequest() {
      this.$store.commit('login/setLoading');

      await this.$store.dispatch('reports/GET_TEMPLATES');
      this.spinnerLoading = true;

      const res = await this.getMeData();

      if (this.me.need_change_password) {
        this.$store.commit('login/SET_CHANGE_PASSWORD', true);
        return;
      }

      this.isNavigation = this.checkCompanyStatus(res);

      if (this.me.role.key === 'OBSERVER') {
        await Promise.all([
          this.$store.dispatch('units/GET_UNITS'),
          this.$store.dispatch('units/GET_GROUP_UNITS'),
        ]);
      } else {
        await this.loadUserData();
      }

      this.$store.commit('login/setLoading');
      this.spinnerLoading = false;
      this.CLEAR_TREK(true);
    },

    async getMeData() {
      const res = await this.$store.dispatch('login/getMe');

      const formatTime = res.active_company?.formatTime || '00:00:00';
      localStorage.setItem('formattedTime', JSON.stringify(formatTime));

      this.$i18n.set(res.lang.key);
      this.setLocale(res.lang.key);

      return res;
    },

    setLocale(langKey) {
      let locale;
      switch (langKey) {
        case 'ru':
          locale = localeRu;
          break;
        case 'en':
          locale = localeEn;
          break;
        default:
          locale = localeKz;
      }
      ElementLocale.use(locale);
    },

    checkCompanyStatus(res) {
      const billing = res.active_company.billing;
      if (billing && billing.tariff.price !== 0) {
        this.isCompanyBlocked = billing.company_status === 'BLOCKED';
        return !this.isCompanyBlocked;
      }
      return true;
    },

    async loadUserData() {
      await Promise.all([
        this.$store.dispatch('properties/GET_ACCESS_RIGHTS'),
        this.$store.dispatch('properties/getAllProperties'),
        this.loadOptionalData()
      ]);
    },

    async loadOptionalData() {
      if (!this.me.forbidden_actions.includes('get_users')) {
        await this.$store.dispatch('users/GET');
      }
      await this.$store.dispatch('trailers/GET');

      if (this.notRole && !this.me.forbidden_actions.includes('cud_locators')) {
        await this.$store.dispatch('locators/GET');
      }

      await Promise.all([
        this.$store.dispatch('mailings/GET'),
        this.$store.dispatch('races/GET_RACES', { from: 0, count: 100 }),
        this.$store.dispatch('routes/GET_ROUTES', { from: 0, count: 100 }),
        this.$store.dispatch('properties/CUSTOM_FIELDS'),
        this.$store.dispatch('dictionary/getParamsUnitSensor'),
        this.$store.dispatch('dictionary/getAllDictionary'),
        this.$store.dispatch('notifications/GET')
      ]);

      await this.getUnitsData();
    },

    async getUnitsData() {
      if (!this.me.forbidden_actions.includes('get_units')) {
        const result = await Promise.all([
          this.$store.dispatch('units/GET_UNITS'),
          this.$store.dispatch('units/GET_GROUP_UNITS'),
        ]);
        if (this.isReport) this.$refs.report?.setFormLocalStorage(result);
      } else {
        const result = await this.$store.dispatch('units/GET_GROUP_UNITS');
        if (this.isReport) this.$refs.report?.setFormLocalStorage(result);
      }

      this.getCacheNotification();
      await this.getPorts();
    },


    recalcStatus(newUnits) {
      // функция перерасчета статусов когда мониторинг закрыт а обьекты на карте есть
      const coords = []
      const markerMap = {}

      newUnits.forEach(item => {
        markerMap[item.unit_id] = item
      })

      Object.values(this.vuexMarkersOnMap).forEach(el => {
        const currentMarkerId = el.id
        const updatedMarker = markerMap[currentMarkerId]
        const isSameMarkerId = updatedMarker && updatedMarker.unit_id === currentMarkerId

        if (isSameMarkerId) {
          const { latlon: updatedLatlon, angle: updatedAngle, datepoint: updatedDatepoint, datepointHuman: updatedDatepointHuman, ignition: updatedIgnition, speed: updatedSpeed, status: updatedStatus, address: updateAddress } = getMarkerValues(updatedMarker, this.formattedCurrentTime)

          const isSameLatlon = updatedLatlon.value[0] === el.latlon.value[0] && updatedLatlon.value[1] === el.latlon.value[1]
          const isSameAngle = updatedAngle === el.angle
          const isSameStatus = updatedStatus === el.status

          el.angle = updatedAngle
          el.datepoint = updatedDatepoint
          el.datepointHuman = updatedDatepointHuman
          el.ignition = updatedIgnition || ''
          el.speed = updatedSpeed
          el.status = updatedStatus
          el.latlon = updatedLatlon || []
          el.address = updateAddress

          // обновление стрелочки у иконки
          const icons = document.querySelector('.skif-marker__arrow-box')

          if (icons && el.angle !== -1 && icons.id === `unit_id_${currentMarkerId}`) {
            icons.style.transform = `rotate(${el.angle}deg)`
          }

          if (this.isActiveMonitoringButton && this.vuexActiveArrow.includes(currentMarkerId)) {
            coords.push(updatedLatlon.value)
          }

          if (!isSameLatlon || !isSameAngle || !isSameStatus) {
            this.markerGroupUnits.removeLayer(this.vuexMarkersUnitOnMap[el.id])
            this.addVuexMarkersUnitOnMap({
              id: el.id,
              unit: this.helperGetMarker(el, el.id)
            })
            this.markerGroupUnits.addLayer(this.vuexMarkersUnitOnMap[el.id])
          }
        }
      })

      if (coords.length) {
        this.lastCoords = coords
      }
      this.helperTrackingMarkers(coords)
    },

    openAnnotationCamera(item) {
      this.cameraItem = item
      this.isAnnotationCamera = true
    },

    closeAnnotationCamera(isActive) {
      this.isAnnotationCamera = isActive
    },

    closeAnnotationCameraFromMonitoring() {
      this.isAnnotationCamera = false
    },

    showModalVideo(stream) {
      this.isShowModalVideo = true
      this.videoStream = stream
    },

    showDeleteMessageModal(item) {
      this.unitItem = item
      this.isDeleteMessageModal = true
    },
    finishTour() {
      this.isDemo = false
      this.isFinishTour = true
      this.isDemoAccount = true // For demo only
      this.showReports()
      // For demo only
      setTimeout(() => {
        this.isDemoAccount = false
      }, 5000)
    },
    showComposeReport(data) {
      this.dataForComposeReport = data
      this.isComposeReport = true
    },
    doImage() {
      console.log('doImage New')
      if (this.drawenMakers && this.drawenMakers.length > 0) {
        Object.keys(this.drawenMakers).forEach(marker => {
          const drawenMarker = this.drawenMakers[marker]
          if (MapInstance.hasLayer(drawenMarker)) {
            MapInstance.removeLayer(drawenMarker)
            setTimeout(() => {
              MapInstance.addLayer(drawenMarker)
            }, 1000)
          }
        })
      }

      if (this.drawenArrows && this.drawenArrows.reports) {
        this.drawenArrows.reports.eachLayer(arrow => {
          arrow.removeFrom(map)
          setTimeout(() => {
            arrow.removeFrom(map)
          }, 1000)
        })
      }

      return new Promise((resolve, reject) => {
        leafletImage(map, (err, canvas) => {
          if (err) reject(err)
          canvas.toBlob(blob => {
            const reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onloadend = () => resolve(reader.result.split(',')[1])
          })
        })
      })
    },
    async handlePrint(params) {
      try {
        const mapIimageBase64 = await this.doImage()
        const formData = {}
        const files = []
        if (params.isPrintMap) {
          files.push(mapIimageBase64)
        }
        if (params.isChart) {
          const imgUri = this.chartBase64.imgURI
          const chartBase64Data = imgUri.replace(/^data:image\/(png|jpeg|jpg);base64,/, '')
          files.push(chartBase64Data)
        }
        formData.files = files
        if (params.formData.units) {
          formData.units = params.formData.units
        }
        if (params.formData.groups) {
          formData.groups = params.formData.groups
        }
        formData.template_tables = params.formData.template_tables
        formData.from = params.formData.from
        formData.to = params.formData.to
        this.downloadReports(params.format, formData)
      } catch (err) {
        console.error(err)
      }
    },
    downloadReports(type, formData) {
      let extension
      switch (type) {
        case 'word':
          extension = 'docx'
          break
        case 'pdf':
          extension = 'pdf'
          break
        case 'excel':
          extension = 'xlsx'
          break
        default:
          extension = 'unknown'
          break
      }
      const link = `/api_v1/report_${type}`
      axios
        .post(link, formData, {
          responseType: 'blob'
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))

          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `all_reports.${extension}`)
          document.body.appendChild(link)
          link.click()

          link.parentNode.removeChild(link)
          window.URL.revokeObjectURL(url)
        })
        .catch(error => {
          console.error(error)
        })
    },
    showAddParametrs(unit) {
      this.addParametrsUnit = unit
      this.isShowAddParametrs = true
    },
    async getSensorsForParametrs() {
      await this.$store.dispatch('dictionary/getParamsUnitSensor')
    },
    refreshReport(data) {
      this.isRefreshReport = data
    },
    /**
     * Handles keydown events on the window object.
     * @param {KeyboardEvent} event - The keydown event object.
     */
    handleKeydown(event) {
      // Check if the key pressed is 's' and the shift key is also pressed
      if (event.code === 'KeyS' && event.shiftKey) {
        if (!(event.target.tagName.toLowerCase() === 'input' || event.target.tagName.toLowerCase() === 'textarea')) {
          // Display the settings panel
          this.isDisplaySettings = !this.isDisplaySettings
        }
      }
    },

    handleCloseReportMain() {
      this.isReport = false;
      this.isMonitoring = false;
    },

    reloadReport() {
      this.isReport = true;
    },
  },

  created() {
    this.updateStatus()
    window.addEventListener('online', this.updateStatus)
    window.addEventListener('offline', this.updateStatus)
    this.getMapView()

    const geoStatus = JSON.parse(localStorage.getItem('hidegeozones'))
    const geoMeasure = JSON.parse(localStorage.getItem('measureGeo'))
    /** AV TMP format time need fix in localStorage */
    let formatTime = this.me.active_company?.formatTime
    if (!formatTime) {
      formatTime = '00:00:00'
    }
    /** AV TMP END */

    const formattedTime = formatTime

    if (formattedTime) {
      this.getFormatTime(formattedTime)
    }

    this.$store.commit('map/TOGGLE_GEOZONES', geoStatus)
    this.$store.commit('geozones/SET_MEASURE', geoMeasure)

    if (this.selectedModule.companyId) {
      companyApi.changeCompany(
        this.selectedModule.companyId,
        () => {
          this.$store.commit('login/COMPANY_CHANGED')
          this.getAllRequest()
          this.closeNotification()
          setTimeout(() => {
            if (this.selectedModule.moduleName && this.statusCompany) {
              switch (this.selectedModule.moduleName) {
                case 'users':
                  this.showUser()
                  break
                case 'units':
                  this.showObjects()
                  break
                case 'geozones':
                  this.showGeozone()
                  break
                case 'notifications':
                  this.showNotification()
                  break
                case 'reports':
                  this.showReports()
                  break
                case 'monitoring':
                  setTimeout(() => {
                    this.showMonitoring()
                  }, 1000)
                  break
                case 'mailing':
                  this.showMailing()
                  this.showUser()
                  break
                case 'race':
                  this.showRace()
                  break

                default:
                  break
              }
            }
          }, 500)
        },
        error => {
          console.log(error, 'error')
        }
      )
    } else {
      this.getAllRequest()
    }
  },
  /**
   * Adds an event listener to the window object for keydown events.
   * When the 'shift+s' keys are pressed, the settings panel is displayed.(DisplaySettings)
   */

  mounted() {
    const typeLogged = localStorage.getItem('typeLogged')
    if (typeLogged === 'demoLogin') {
      this.isDemo = true
    }
    // Listen for keydown events on the window object (shift+s) to display the settings panel
    window.addEventListener('keydown', this.handleKeydown)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeydown)
  }
}
</script>

<style>
.custom-tooltip {
  display: flex;
  align-items: center;
  padding: 6px;
  white-space: nowrap;
  position: absolute;
  background: rgb(255, 255, 255);
  border: 1px solid #cdd4dd;
  border-radius: 6px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 100%;
  color: #595959;
}
.expand-arrow,
.back-arrow {
  margin-left: 5px;
  padding-top: 2px;
  padding-left: 2px;
}
.full-text {
  display: none;
  overflow: hidden;
  margin-right: 6px;
  animation: expandTooltip 0.3s ease-in-out;
}
@keyframes expandTooltip {
  from {
    max-width: 0;
  }
  to {
    max-width: 100%;
  }
}

.expanded .short-text {
  display: none;
}

.expanded .full-text {
  display: block;
}

.el-icon-back {
  display: none;
}
.el-tooltip__popper {
  max-width: 800px !important;
}
.el-tooltip__popper.is-dark {
  background: #5477a9 !important;
  opacity: 0.8;
}

.leaflet-draw-toolbar {
  display: none !important;
}

.el-notification__group {
  word-break: break-word;
}

.el-notification__group .el-notification__content {
  text-align: left;
}

.blockedCompany {
  z-index: 2 !important;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 403px !important;
  height: 100px !important;
  padding-left: 20px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;

  border-left: 6px solid #eb5757 !important;
  border-radius: 0px !important;
}

.domain-link {
  color: #5477a9;
  font-weight: bold;
}

.domain-notification {
  display: inline-block;
  text-align: left;
}

.select-layers {
  position: absolute;
  top: 56%;
  right: 22px;
}

.measure-tool {
  position: absolute;
  top: 59%;
  right: 22px;
}

.help-tool {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.chat-tool {
  position: absolute;
  bottom: 20px;
  left: 64px;
}
.helper {
  position: absolute;
  top: 38px;
  height: 36px;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background: #388bf1;
  width: 340px;
  color: #fff;
}
.edit-overlay {
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  margin: 0 !important;
  border-radius: 0;
  transition: background-color 1s;
}
</style>
